/* eslint-disable no-unused-expressions */
/**
 * Class representing a Ace Callout Container Component instance
 * @author Rishabh
 * @class NovotelCalloutContainer
 * @classdesc Novotel callout container component to inherit the callout container core component
 * @extends CalloutContainer
 * @version 1.0
 */
import { CalloutContainer } from '@accor/ace-ui-core';
import {
  handleEventTracking,
  getDataLayer,
  PAGE_LOYALTY,
  PAGE_MAGAZINE_HUB,
  PAGE_MAGAZINE_CATEGORY,
  PAGE_LA_SUITE_NOVOTEL,
} from '../../../../../../utils/tracking';

export default class NovotelCalloutContainer extends CalloutContainer {
  constructor(componentHost) {
    super(componentHost);

    ({ pageName: this.pageName } = getDataLayer());
    this.pageHeadingTitle = document.querySelector('.heading-hero .cmp-headingpagehero-title')?.innerText?.trim()?.toLowerCase();

    this.handleCalloutContainerItemsTracking();
    this.handleCalloutLoyaltyCtaTracking();
    this.handleCalloutPushCtaTracking();
    this.calloutUserLoginStatus();
  }

  calloutUserLoginStatus() {
    const buttonmember = this.componentHost.querySelector('.callout-container .call-to-action .ace-button .cmp-button ');
    if (buttonmember && CoreJS.CommonUtils.userLoginStatus() === true) {
      buttonmember.classList?.add('btnmemberhidden');
    }
  }

  handleCalloutContainerItemsTracking() {
    const calloutContainerItems = this.componentHost.querySelectorAll('.callout-section ul li');

    if (!this.pageName || !calloutContainerItems.length) return;

    const trackItem = (element, index) => {
      const defaultEventData = {
        node: element.querySelector('.ace-callout-component'),
        htmlEventType: CoreJS.DomEventConstants.CLICK,
        eventName: 'bloc_interact',
      }
      const calloutTitle = element?.querySelector('.cmp-teaser__pretitle')?.innerText?.trim()?.toLowerCase();

      const eventDataMap = {
        [PAGE_MAGAZINE_HUB]: {
          ...defaultEventData,
          additionalData: {
            bloc_interaction: calloutTitle,
            bloc_name: element?.closest('.callout-container')?.previousElementSibling?.querySelector('.ace-headingpagehero-headline [id]')?.innerText?.trim()?.toLowerCase(),
          }
        },
        [PAGE_MAGAZINE_CATEGORY]: {
          ...defaultEventData,
          additionalData: {
            bloc_interaction: calloutTitle,
            bloc_name: this.pageHeadingTitle,
          }
        },
        [PAGE_LA_SUITE_NOVOTEL]: {
          ...defaultEventData,
          additionalData: {
            bloc_interaction: calloutTitle,
            bloc_name: 'limitless experience',
            bloc_type: String(index + 1),
          }
        }
      }

      handleEventTracking(eventDataMap[this.pageName] ?? defaultEventData)
    }

    calloutContainerItems.forEach(trackItem);
  }

  handleCalloutLoyaltyCtaTracking() {
    const calloutContainerCta = this.componentHost.querySelector('.ace-loyalty__inner');

    const eventDataMap = {
      [PAGE_MAGAZINE_HUB]: {
        additionalData: {
          bloc_interaction: 'see all articles',
          bloc_name: calloutContainerCta?.closest('.callout-container')?.previousElementSibling?.querySelector('.ace-headingpagehero-headline [id]')?.innerText?.trim()?.toLowerCase(),
        }
      },
      [PAGE_MAGAZINE_CATEGORY]: {
        additionalData: {
          bloc_interaction: 'see all articles',
          bloc_name: this.pageHeadingTitle,
        }
      }
    }
    const eventData = eventDataMap[this.pageName]

    if (eventData) {
      handleEventTracking({
        node: calloutContainerCta,
        htmlEventType: CoreJS.DomEventConstants.CLICK,
        eventName: 'bloc_interact',
        ...eventData,
      })
    }
  }

  handleCalloutPushCtaTracking() {
    const calloutCallToAction = this.componentHost?.querySelector('.call-to-action .ace-button a');
    const eventDataMap = {
      [PAGE_LOYALTY]: {
        eventName: 'cta_enroll',
        additionalData: {
          enroll_context: 'loyalty',
          cta_name: calloutCallToAction?.innerText?.trim()?.toLowerCase(),
          contexte: 1
        }
      },
      [PAGE_LA_SUITE_NOVOTEL]: {
        eventName: 'bloc_interact',
        additionalData: {
          bloc_name: 'limitless experience',
          bloc_interaction: 'see more experiences',
        }
      }
    }

    const eventData = eventDataMap[this.pageName]

    if (eventData) {
      handleEventTracking({
        node: calloutCallToAction,
        htmlEventType: CoreJS.DomEventConstants.CLICK,
        ...eventData,
      })
    }
  }
}

CoreJS.BaseComponent.registerComponent(CalloutContainer.CLASS_NAMESPACE, NovotelCalloutContainer, true);
