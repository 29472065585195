import { Newsletter } from '@accor/ace-ui-core';
import { handleEventTracking, getDataLayer, PAGE_LA_SUITE_NOVOTEL } from "../../../../../utils/tracking"

class NovotelNewsletter extends Newsletter {

  constructor(componentHost: HTMLElement) {
    super(componentHost)
    const { pageName } = getDataLayer()

    if (pageName === PAGE_LA_SUITE_NOVOTEL) {
      const button: HTMLElement | null = componentHost.querySelector(".cmp-button")

      handleEventTracking({
        node: button,
        htmlEventType: CoreJS.DomEventConstants.CLICK,
        eventName: "newsletter_submit",
        additionalData: { newsletter_name: "novotel" }
      })
    }
  }
}

CoreJS.BaseComponent.registerComponent(Newsletter.CLASS_NAMESPACE, NovotelNewsletter, true)
