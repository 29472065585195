import {
  handleEventTracking,
  getDataLayer,
  currentComponentIsNextTo,
  PAGE_LA_SUITE_NOVOTEL,
} from "../../../../../utils/tracking"

class NovotelHighlight extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "highlight"

  constructor(componentHost: HTMLElement) {
    super(componentHost)
    const { pageName } = getDataLayer()

    if (pageName === PAGE_LA_SUITE_NOVOTEL) {
      const button: HTMLElement | null = componentHost.querySelector(".ace-button a")
      let bloc_name: string
      let bloc_interaction: string

      if (currentComponentIsNextTo(componentHost, "heading")) {
        bloc_name = "book suite"
        bloc_interaction = "2"
      } else {
        bloc_name = "family inspirations"
        bloc_interaction = "family stay"
      }

      handleEventTracking({
        node: button,
        htmlEventType: CoreJS.DomEventConstants.CLICK,
        eventName: "bloc_interact",
        additionalData: { bloc_name, bloc_interaction }
      })
    }
  }
}

CoreJS.BaseComponent.registerComponent(NovotelHighlight.CLASS_NAMESPACE, NovotelHighlight, true)
