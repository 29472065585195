
import { handleEventTracking, getDataLayer, PAGE_LA_SUITE_NOVOTEL } from "../../../../../utils/tracking"

class NovotelHeading extends CoreJS.BaseComponent {
  static CLASS_NAMESPACE = "heading"

  constructor(componentHost: HTMLElement) {
    super(componentHost)
    const { pageName } = getDataLayer()

    if (pageName === PAGE_LA_SUITE_NOVOTEL) {
      const button: HTMLElement | null = componentHost.querySelector(".cmp-button")

      handleEventTracking({
        node: button,
        htmlEventType: CoreJS.DomEventConstants.CLICK,
        eventName: "bloc_interact",
        additionalData: {
          bloc_name: "book suite",
          bloc_interaction: "1" ,
        }
      })
    }
  }
}

CoreJS.BaseComponent.registerComponent(NovotelHeading.CLASS_NAMESPACE, NovotelHeading, true)
